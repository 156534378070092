
	import { Component, Vue } from 'vue-property-decorator';
	import { setupBaseMetaTags } from '@/utils/seo';

	import { HomepageSection } from '@/models/store/organism.interface';
	import TopicCardsSection from '@/components/homepage/TopicCardsSection.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import ExploreItemTypeBanner from '@/components/banners/ExploreItemTypeBanner.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import HomepagePackList from '@/components/pixcap-library/library-packs/HomepagePackList.vue';
	import AdvertiseSection from '@/components/homepage/AdvertiseSection.vue';
	import CompatibleSection from '@/components/homepage/CompatibleSection.vue';
	import PageHeading from '@/components/pixcap-library/shared/PageHeading.vue';
	import EditColorBanner from '@/components/banners/EditColorBanner.vue';
	import InteractiveBannerSection from '@/components/homepage/InteractiveBannerSection.vue';

	// Partners logo
	import IconTrueGrayed from '@pixcap/ui-library/components/Icons/brands/IconTrueGrayed.vue';
	import IconNvidiaGrayed from '@pixcap/ui-library/components/Icons/brands/IconNvidiaGrayed.vue';
	import IconGrabGrayed from '@pixcap/ui-library/components/Icons/brands/IconGrabGrayed.vue';
	import IconAirBnbGrayed from '@pixcap/ui-library/components/Icons/brands/IconAirBnbGrayed.vue';
	import IconESTGrayed from '@pixcap/ui-library/components/Icons/brands/IconESTGrayed.vue';
	import IconMicrosoftGrayed from '@pixcap/ui-library/components/Icons/brands/IconMicrosoftGrayed.vue';
	import IconBharatPeGrayed from '@pixcap/ui-library/components/Icons/brands/IconBharatPeGrayed.vue';
	import IconShopeeGrayed from '@pixcap/ui-library/components/Icons/brands/IconShopeeGrayed.vue';
	import IconAdpListGrayed from '@pixcap/ui-library/components/Icons/brands/IconAdpListGrayed.vue';

	@Component({
		name: 'LibraryExploreList',
		components: {
			InteractiveBannerSection,
			EditColorBanner,
			PageHeading,
			CompatibleSection,
			AdvertiseSection,
			HomepagePackList,
			ExploreItemTypeBanner,
			BodyText,
			TopicCardsSection,
		},
	})
	export default class LibraryExploreList extends Vue {
		LIBRARY_TAB = LIBRARY_TAB;
		HomepageSection = HomepageSection;
		partnersLogo = [
			IconNvidiaGrayed,
			IconGrabGrayed,
			IconAirBnbGrayed,
			IconTrueGrayed,
			IconESTGrayed,
			// 2nd row
			IconMicrosoftGrayed,
			IconBharatPeGrayed,
			IconShopeeGrayed,
			IconAdpListGrayed,
		];

		head() {
			const headMeta: any = {
				meta: [],
			};
			const metaTagsPayload: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			setupBaseMetaTags(metaTagsPayload, headMeta, this.$route);
			return headMeta;
		}
	}
