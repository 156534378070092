
	import { Vue, Component } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import { UserDeviceTypes } from '@pixcap/ui-core/models/store/app.interface';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';

	@Component({
		components: { IntersectVideoAutoplay, BodyText, HeadingText },
	})
	export default class TopicCardsSection extends Vue {
		get cards() {
			return [
				{
					posterUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/icons.webp`,
					thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/icons${
						this.$pixcap.$deviceType === UserDeviceTypes.MOBILE ? '-mobile' : ''
					}.mp4`,
					isVideoThumbnail: true,
					title: this.$t('common_elements.icons'),
					to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } },
				},
				{
					thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/characters${
						this.$pixcap.$deviceType === UserDeviceTypes.MOBILE ? '-mobile' : ''
					}.webp`,
					isVideoThumbnail: false,
					posterUrl: null,
					title: this.$t('common_elements.characters'),
					to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } },
				},
				{
					thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/mockups${
						this.$pixcap.$deviceType === UserDeviceTypes.MOBILE ? '-mobile' : ''
					}.webp`,
					isVideoThumbnail: false,
					posterUrl: null,
					title: this.$t('common_elements.mockups'),
					to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } },
				},
			];
		}
	}
