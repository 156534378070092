
	import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import { LIBRARY_TAB, LibraryFileType, LibraryQueryTypes } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { HomepageSection } from '@/models/store/organism.interface';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PixcapLibraryPack from '@/components/pixcap-library/shared/PixcapLibraryPack.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	@Component({
		name: 'HomepagePackList',
		components: { Button, PixcapLibraryPack, BodyText, IconCaretRight, HeadingText, LibraryItemListLoader },
	})
	export default class HomepagePackList extends Vue {
		@Prop() title: string;
		@Prop() description: string;
		@Prop() type: HomepageSection;

		@InjectReactive() windowInnerWidth: number;

		isFetchingPacks = false;
		packItems = [];
		LIBRARY_TAB = LIBRARY_TAB;
		HomepageSection = HomepageSection;

		get headingTitle() {
			if (this.title) return this.title;
			return 'Packs';
		}

		get isMockupList() {
			return [HomepageSection.BRANDING_MOCKUP, HomepageSection.DEVICE_MOCKUP].includes(this.type);
		}

		get resourceType() {
			switch (this.type) {
				case HomepageSection.ICON:
				case HomepageSection.ANIMATED_ICON:
					return [
						LIBRARY_RESOURCE_TYPE.MODELS,
						LIBRARY_RESOURCE_TYPE.FRAMES,
						LIBRARY_RESOURCE_TYPE.TEXT,
						LIBRARY_RESOURCE_TYPE.TEXT_SHAPES,
						LIBRARY_RESOURCE_TYPE.SHAPES,
					];
				case HomepageSection.CHARACTER:
					return [LIBRARY_RESOURCE_TYPE.CHARACTERS];
				case HomepageSection.BRANDING_MOCKUP:
				case HomepageSection.DEVICE_MOCKUP:
					return [LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES];
				default:
					return [];
			}
		}

		get tagQuery() {
			if (this.isMockupList) {
				if (this.type === HomepageSection.BRANDING_MOCKUP) return this.$t('tag_name.brand_kit') as string;
				return this.$t('tag_name.device') as string;
			}
			return undefined;
		}

		handleShowAll() {
			let tab = null;
			let query = null;
			switch (this.type) {
				case HomepageSection.ANIMATED_ICON:
					tab = LIBRARY_TAB.THREE_D_PACKS;
					query = {
						[LibraryQueryTypes.TYPE]: LibraryFileType.ANIMATED,
					};
					break;
				case HomepageSection.ICON:
					tab = LIBRARY_TAB.THREE_D_PACKS;
					break;
				case HomepageSection.CHARACTER:
					tab = LIBRARY_TAB.THREE_D_CHARACTERS;
					break;
				case HomepageSection.BRANDING_MOCKUP:
				case HomepageSection.DEVICE_MOCKUP:
					tab = LIBRARY_TAB.MOCKUPS;
					query = {
						[LibraryQueryTypes.TAG]: this.tagQuery,
					};
					break;
				default:
					tab = LIBRARY_TAB.THREE_D_PACKS;
			}
			LibraryMutations.setExplorerTab(this.$store, tab);
			this.$router.push({
				name: 'LibraryPage',
				params: { tab },
				query,
			});
		}

		get numberOfPackPerPage() {
			return 8;
		}

		async fetchPacks() {
			if (this.isFetchingPacks) return;
			this.isFetchingPacks = true;
			const response = await LibraryActions.fetchPacksList(this.$store, {
				refresh: false,
				page: 0,
				pageSize: this.numberOfPackPerPage,
				skipStore: true,
				isBrandKit: this.isMockupList,
				tagSearch: this.tagQuery,
				hasAnimated: this.type === HomepageSection.ANIMATED_ICON ? true : undefined,
				resourceTypes: this.resourceType,
			});

			if (response) {
				this.packItems = response.content;
			}

			this.isFetchingPacks = false;
		}

		async fetch() {
			await this.fetchPacks();
		}
	}
